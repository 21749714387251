import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AngularFireAuth} from 'angularfire2/auth';
import {auth} from 'firebase/app';
import {AngularFirestore} from 'angularfire2/firestore';

@Injectable({
	providedIn: 'root'
})
export class ContactService {
	private visble = new BehaviorSubject('false');
	private showMessage = new BehaviorSubject('false');

	currentState = this.visble.asObservable();
	currentStateMessage = this.showMessage.asObservable();

	authState: any = null;

	changeState (message: string) {
		this.visble.next(message);
	}

	changeStateMessage () {
		this.showMessage.next('true');
	}

	constructor (public afAuth: AngularFireAuth, private afs: AngularFirestore) {
	}

	// Returns true if user is logged in
	get authenticated (): boolean {
		return this.authState !== null;
	}

	// Returns current user data
	get currentUser (): any {
		return this.authenticated ? this.authState : null;
	}

	// Returns
	get currentUserObservable (): any {
		return this.afAuth.authState;
	}

	// Returns current user UID
	get currentUserId (): string {
		return this.authenticated ? this.authState.uid : '';
	}

	googleLogin () {
		const provider = new auth.GoogleAuthProvider();
		return this.socialSignIn(provider);
	}

	facebookLogin () {
		const provider = new auth.FacebookAuthProvider();
		return this.socialSignIn(provider);
	}

	twitterLogin () {
		const provider = new auth.TwitterAuthProvider();
		return this.socialSignIn(provider);
	}

	private socialSignIn (provider) {
		return this.afAuth.auth.signInWithPopup(provider)
			.then((credential) => {
				this.authState = credential.user;
				this.updateUserData();
			})
			.catch((error) => {
				switch (error.code) {
					case 'auth/popup-closed-by-user':
						alert('Registro cancelado por el usuario');
						break;
				}
				console.log(error);
			});
	}

	private updateUserData (): void {
		const path = `users/${this.currentUserId}`;
		const data = {
			email: this.authState.email,
			name: this.authState.displayName,
			photo: this.authState.photoURL,
			phone: this.authState.phoneNumber
		};

		this.afs.doc(path).set(data).then(() => {
			this.changeStateMessage();
			setTimeout(() => {
				this.changeState('false');
			}, 3000);
		})
			.catch(error => console.log(error));

	}

}
