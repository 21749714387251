import {Component, OnInit} from '@angular/core';
import {ContactService} from '../contact.service';
import {ToastrService} from 'ngx-toastr';

@Component({
	selector: 'app-contact-form',
	templateUrl: './contact-form.component.html',
	styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {

	public show;
	message: string;

	constructor (private service: ContactService, private toastr: ToastrService) {
		this.message = 'false';
	}

	ngOnInit () {
		this.service.currentState.subscribe(message => this.show = message);
		this.service.currentStateMessage.subscribe((message) => {
			this.message = message;
		});
	}

	google () {
		this.service.googleLogin().then(() => {
			this.postLogin();
		}).catch((error) => {
			console.log(error);
		});
	}

	facebook () {
		this.service.facebookLogin().then(() => {
			this.postLogin();
		}).catch((error) => {
			console.log(error);
		});
	}

	twitter () {
		this.service.twitterLogin().then(() => {
			this.postLogin();
		}).catch((error) => {
			console.log(error);
		});
	}

	postLogin () {
		if (this.service.authState) {
			this.toastr.success(`te notificaremos al correo ${ this.service.authState.email} cuando liberemos DomesticApp para IOS`,
				'Perfecto'
			);

		}
	}

}
