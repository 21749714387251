import { Component, Injectable } from '@angular/core';
import { Inject, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

declare var $: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})

@Injectable()
export class AppComponent implements AfterViewInit {

	public title = 'DomesticApp';

	ngAfterViewInit (): void {
		// jQuery for page scrolling feature - requires jQuery Easing plugin
		$('a.page-scroll').on('click', function (event) {
			const $anchor = $(this);
			$('html, body').stop().animate({
				scrollTop: ($($anchor.attr('href')).offset().top - 50)
			}, 1250, 'easeInOutExpo');
			event.preventDefault();
		});

		// Highlight the top nav as scrolling occurs
		$('body').scrollspy({target: '.navbar-fixed-top', offset: 100});

		// Closes the Responsive Menu on Menu Item Click
		$('.navbar-collapse ul li a').click(function () {
			$('.navbar-toggle:visible').click();
		});

		// Offset for Main Navigation

		$('#mainNav').affix({
			offset: {
				top: 50
			}
		});
	}
}
