import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpModule} from '@angular/http';
import {AppComponent} from './app.component';
import {MainNavComponent} from './main-nav/main-nav.component';
import {PageHeaderComponent} from './page-header/page-header.component';
import {PageDownloadComponent} from './page-download/page-download.component';
import {PageFeaturesComponent} from './page-features/page-features.component';
import {PageCtaComponent} from './page-cta/page-cta.component';
import {PageContactComponent} from './page-contact/page-contact.component';
import {PageFooterComponent} from './page-footer/page-footer.component';
import {NgxTwitterTimelineModule} from 'ngx-twitter-timeline';
import {ContactFormComponent} from './contact-form/contact-form.component';
import {ContactService} from './contact.service';
import {AngularFireModule} from 'angularfire2';
import {AngularFireAuthModule} from 'angularfire2/auth';
import {AngularFirestoreModule} from 'angularfire2/firestore';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({
	declarations: [
		AppComponent,
		MainNavComponent,
		PageHeaderComponent,
		PageDownloadComponent,
		PageFeaturesComponent,
		PageCtaComponent,
		PageContactComponent,
		PageFooterComponent,
		ContactFormComponent,
	],
	imports: [
		BrowserModule,
		FormsModule,
		HttpModule,
		NgxTwitterTimelineModule.forRoot(),
		AngularFireModule.initializeApp(
			{
				apiKey: 'AIzaSyApph5nNol-7U3OTF4k3U-Ww5_DOOPMx1c',
				authDomain: 'domesticapp-f28.firebaseapp.com',
				databaseURL: 'https://domesticapp-f28.firebaseio.com',
				projectId: 'domesticapp-f28',
				storageBucket: 'domesticapp-f28.appspot.com',
				messagingSenderId: '202494938581'
			}
		),
		BrowserAnimationsModule,
		ToastrModule.forRoot(
			{
				timeOut: 10000,
				positionClass: 'toast-bottom-right',
				preventDuplicates: true,
				closeButton: true,
				progressBar: true
			}
		),
		AngularFirestoreModule,
		AngularFireAuthModule
	],
	providers: [ContactService],
	bootstrap: [AppComponent]
})

export class AppModule {
}
