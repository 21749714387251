import {Component} from '@angular/core';

@Component({
	selector: 'page-cta',
	templateUrl: './page-cta.component.html'
})

export class PageCtaComponent {

}
