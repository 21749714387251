import {Component} from '@angular/core';

@Component({
	selector: 'page-features',
	templateUrl: './page-features.component.html'
})

export class PageFeaturesComponent {

}
