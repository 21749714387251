import {Component} from '@angular/core';

@Component({
	selector: 'page-footer',
	templateUrl: './page-footer.component.html'
})

export class PageFooterComponent {

}
