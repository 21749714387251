import {Component} from '@angular/core';

@Component({
	selector: 'page-header',
	templateUrl: './page-header.component.html'
})

export class PageHeaderComponent {

}
