import {Component} from '@angular/core';
import {ContactService} from '../contact.service';

@Component({
	selector: 'page-download',
	templateUrl: './page-download.component.html'
})

export class PageDownloadComponent {

	show: string;
	message: string;

	constructor (private data: ContactService) {
		this.message = 'false';
		this.data.currentStateMessage.subscribe((message) => {
			this.message = message;
		});
	}

	showForm (event) {
		event.preventDefault();
		this.data.changeState('true');

	}

}
