import {Component} from '@angular/core';

@Component({
	selector: 'page-contact',
	templateUrl: './page-contact.component.html'
})

export class PageContactComponent {

}
