import {Component} from '@angular/core';

@Component({
	selector: 'main-nav',
	templateUrl: './main-nav.component.html'
})
export class MainNavComponent {
	public title = 'DomesticApp';
	public navOptions = [
		{title: 'Inicio', path: '#page-top'},
		{title: 'Características', path: '#features'},
		{title: 'Descargar', path: '#download'},
		{title: 'Contacto', path: '#contact'}
	];
}
